<template>
<div class="meg_all">
    <div class="meg">
    <h1>账单({{User.xingming}})</h1>
    <div class="meg_h">
        <div class="meg_scroll" @scroll="getPreMo" ref="meg_scroll">
          <div class="zhangd_m" v-for="item in zdList" :key="item.month">
            <div class="zhangd_m_1">
              <div class="zhangd_m_1_1"><strong>{{item.year}}</strong>年<strong>{{item.month}}</strong>月</div>
              <div class="zhangd_m_1_2">
                <ul>
                  <li>充值 <strong class="zhifu_jianhao">+</strong><strong>￥{{item.yue}}</strong></li>
                  <li class="hidden" :title="resTitle(item.cishu)"><span>次数</span>
                    <template v-for="ci in item.cishu" :key="ci.name">
                      {{ci.name}}<strong class="zhifu_jianhao">+</strong><strong>{{ci.num}}</strong>次
                    </template>
                  </li>
                </ul>
                <ul>
                  <li>消费 <strong class="zhifu_jianhao">-</strong><strong>￥{{item.xfyue}}</strong></li>
                  <li class="hidden" :title="resTitle(item.xfcishu)"><span>次数</span>
                    <template v-for="ci in item.xfcishu" :key="ci.name">
                      {{ci.name}}<strong class="zhifu_jianhao">-</strong><strong>{{ci.num}}</strong>次
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <table>
              <colgroup>
                <col width="60%" />
                <col width="20%" />
              </colgroup>
              <tbody>
                <tr v-for="jl in item.list" :key="jl.date" @click="goUrl(jl)">
                  <td>
                    <p>（{{jl.fenpeimendianName}}） {{jl.name}}</p>
                    <p>{{jl.date}} <span style="font-size:12px;color:red">{{jl.resetStr}}</span></p>
                  </td>
                  <td>
                    <p :class="jl.lx==1?'gre':'red'"><strong class="zhifu_jianhao">{{jl.lx==1?'+':'-'}}</strong><strong>{{jl.yue}}</strong></p>
                    <p><strong class="zhifu_jianhao"></strong>{{jl.nowyue}}</p>
                  </td>
                  <td>
                    <p :class="jl.lx==1?'gre':'red'" v-for="ci in jl.csList" :key="ci.name">{{ci.name}}<strong class="zhifu_jianhao">{{jl.lx==1?'+':'-'}}</strong><strong>{{ci.num}}</strong>次</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
    <div class="meg_c"><button type="button" class="off" @click="closdMeg()">关闭</button></div>
    <div class="meg_cld" @click="closdMeg()"><i class="icon-x"></i></div>
    </div>
</div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
import { mapMutations } from "vuex"
export default {
  name: 'home',
  props: {
    User:Object,
  },
  data () {
    return {
      form:{
        start:moment().startOf('M').format("YYYY-MM-DD"),
        end:moment().endOf('M').format("YYYY-MM-DD"),
      },
      dataOn:true,
      zdList:[],
      fenpeimendianId:this.$store.state.User.fenpeimendianId,
    }
  },
  created () {},
  mounted () {
    this.init()
  },
  computed:{
    powShow(){
      return (pow)=>{
        if(this.$store.state.User){
          if(this.$store.state.User.jibieId==0||(this.$store.state.User.pow&&this.$store.state.User.pow.indexOf(pow)>=0)){
            return true
          }else return false
        }else return false
      }
    },
  },
  components: {},
  methods: {
    ...mapMutations(['addNav']),
    goUrl(jl){
      if(jl.lx==1){
        if(this.powShow(3400)){
          this.addNav({name:'充值记录',path:'/rechargeRecord'})
          this.$router.push({path:"/rechargeRecord",query:{id:jl.id}})
        }
      }else{
        if(this.powShow(3500)){
          this.addNav({name:'消费记录',path:'/consumptionRecords'})
          this.$router.push({path:"/consumptionRecords",query:{id:jl.id}})
        }
      }
    },
    getPreMo(e){
      let node=e.target?e.target:e
      if(this.dataOn&&node.clientHeight+node.scrollTop+10>=node.scrollHeight){
        let start=moment(this.form.start).add(-1,"M").startOf('M')
        let end = moment(this.form.end).add(-1,"M").endOf('M')
        this.form.start=start.format("YYYY-MM-DD")
        this.form.end=end.format("YYYY-MM-DD")
        this.dataOn=false
        if(end.isAfter(moment().add(-1,"y"))){
          this.getData()
        }
      }
    },
    resTitle(arr){
      if(arr.length){
        let str=''
        arr.forEach(e=>{
          str+=`${e.name} ${e.num} 次 \n`
        })
        return str
      }else return ''
    },
    getData(){
      axios.post('/record/zhangdanJL',{
        userId:this.User.id,
        ...this.form,
      }).then((res) => {
        if(res.code===5000){
          const {cz,xf,xfcs}=res.data
          let czyue=0,cishu=[]
          let czlist=cz.map(e=>{
            if(e.state==1){
              czyue+=e.chongzhijine||0
              czyue+=e.zengsongjine||0
            }
            let csList=[]
            e.cishu.split("；").forEach(a => {
              const b=a.split("&nbsp;")
              if(b[1]){
                if(e.state==1){
                  let c=cishu.find(d=>d.name==b[0])
                  if(c){
                    c.num+=parseInt(b[1])
                  }else{
                    cishu.push({name:b[0],num:parseInt(b[1])})
                  }
                }
                csList.push({name:b[0],num:parseInt(b[1])})
              }
            });
            let resetStr=e.state==1?'':moment(e.resetDate).format("YYYY-MM-DD HH:mm:ss")+' 撤销 ('+e.resetName+')'
            return{
              id:e.id,
              lx:1,
              resetStr,
              name:e.packageName,
              date:moment(e.chongzhishijian).format("YYYY-MM-DD HH:mm:ss"),
              jine:e.fukuanjine,
              yue:parseFloat((e.chongzhijine+e.zengsongjine).toFixed(2)),
              nowyue:parseFloat((e.nowyue+e.nowzengsongyue).toFixed(2)),
              fenpeimendianName:e.fenpeimendianName,
              csList,
            }
          })
          let xfyue=0,xfcishu=[]
          xfcs.forEach(e=>{
            if(e.state==1){
              let c=xfcishu.find(d=>d.name==e.xiangmuName)
              if(c){
                c.num+=parseInt(e.num)
              }else{
                xfcishu.push({name:e.xiangmuName,num:parseInt(e.num)})
              }
            }
          })
          let xflist=xf.map(e=>{
            if(e.state==1){
              xfyue+=e.shifukuan||0
            }
            let resetStr=e.state==1?'':moment(e.resetDate).format("YYYY-MM-DD HH:mm:ss")+' 撤销 ('+e.resetName+')'
            return{
              id:e.id,
              lx:2,
              resetStr,
              name:e.items,
              date:moment(e.xiaofeishijian).format("YYYY-MM-DD HH:mm:ss"),
              jine:e.shifukuan,
              yue:e.shifukuan,
              fenpeimendianName:e.fenpeimendianName,
              nowyue:e.nowyue+e.nowzsyue,
              csList:xfcs.filter(a=>a.jiluId==e.id).map(b=>{
                return {
                  name:b.xiangmuName,
                  num:b.num
                }
              }),
            }
          })
          let list=[...xflist,...czlist].sort((a,b)=>{
            return new Date(b.date).getTime() - new Date(a.date).getTime()
          })
          let zdOBJ={
            yue:czyue,
            xfyue,
            cishu,
            xfcishu,
            year:moment(this.form.start).get('y'),
            month:moment(this.form.start).get('M')+1,
            list,
          }
          this.zdList.push(zdOBJ)
          this.dataOn=true
          this.getPreMo(this.$refs.meg_scroll)
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    init(){
      this.getData()
    },
    closdMeg () {
      this.$emit('closdMeg')
    },
  }
}
</script>
