<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span class="import">会员姓名：</span><input type="text" v-model="form.xingming"/></li>
            <li class="up"><span class="import">手机号码：</span><input type="text" v-model="form.phone" maxlength="11"/></li>
            <li class="up"><span>会员卡号：</span><input type="text" v-model="form.vipcode"/></li>
            <li class="up" v-if="$store.state.User.jibieId==0"><span class="import">属于门店：</span>
              <select v-model="form.fenpeimendianId" @change="setStoreName">
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
            <li class="up" v-else><span class="import">属于门店：</span>{{form.fenpeimendianName}}</li>
            <li class="up"><span class="import">会员等级：</span>
              <select v-model="form.VIPLVId" @change="setVIPLVName">
                <option value="0" >无等级</option>
                <option :value="item.id" v-for="item in VIPLVList" :key="item.id">{{item.VIPLVName}}</option>
              </select>
            </li>
            <li class="up"><span>余额(赠送)：</span><strong>{{parseFloat((form.yue+form.zengsongyue).toFixed(2))}}</strong>（{{parseFloat(form.yue.toFixed(2))+"+"+parseFloat(form.zengsongyue.toFixed(2))}}）</li>
            <li class="abs"><span>账户次数：</span>
              <p v-for="item in form.cishu" :key="item.id">{{item.xiangmuName}} {{item.num}} 次 有效期至{{getDate(item.youxiaoqi)}}</p>
            </li>
            <li class="up"><span>账户积分：</span><input type="number" v-model="form.jifen"/></li>
            <li class="up"><span>会员范围：</span><label><input type="radio" value="1" v-model="form.huiyuanfanwei" :disabled="$store.state.User.daoqishijian<0"/>连锁</label><label><input type="radio" value="2" v-model="form.huiyuanfanwei" :disabled="$store.state.User.daoqishijian<0"/>单店</label><img src="@/assets/images/vip.png"></li>
            <li class="up"><span>专属客服：</span>
              <select v-model="form.zhuanshukefuId" @change="setEmpName" :disabled="$store.state.User.daoqishijian<0">
                <option :value="item.id" v-for="item in empList" :key="item.id">{{item.xingming}}</option>
              </select>
              <img src="@/assets/images/vip.png">
            </li>
            <li class="up"><span>会员性别：</span><label><input type="radio" value="男" v-model="form.xingbie"/>男</label><label><input type="radio" value="女" v-model="form.xingbie"/>女</label></li>
            <li class="up"><span>车牌号码：</span><input type="text" v-model="form.chepaihao"/></li>
            <li class="up"><span>会员生日：</span><input type="date" v-model="form.shengri"/></li>
            <li class="up"><span>备注信息：</span><textarea v-model="form.info"></textarea></li>
            <li class="up"><span>上次消费时间：</span>{{getDateTime(form.scxfTime)}}</li>
            <li class="up"><span>上次消费项目：</span>{{getItemStr(form.scxfjl)}}</li>
            <li class="up"><span>上次服务人员：</span>{{form.shangyicikefuName}}</li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {testStr} from '@/utils/util'
import moment from 'moment'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  data() {
    return {
      userErrClass:'',
      userErr:'',
      empList:[],
      storeList:[],
      VIPLVList:[],
      form:{
        yue:0,
        zengsongyue:0,
      },
    }
  },
  watch:{
    'MmsData.data.id':{
      handler(n){
        this.getVIPUserById(n)
      },
    }
  },
  created() {
    this.init()
  },
  components: {},
  mounted() {},
  methods: {
    getItemStr(arr){
      if(arr&&arr.length){
        let aa=arr.map(e=>{
          return e.itemName
        })
        return aa.join("、")
      }else return ''
    },
    getDateTime(date){
      if(date) return moment(date).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    getDate(date){
      if(date) return moment(date).format("YYYY-MM-DD")
      else return ''
    },
    init(){
      if(this.$store.state.User.jibieId==0){
        this.getStore()
      } 
      if(this.$store.state.User.daoqishijian>=0) this.getEmpList()
      this.getVIPLVList()
      this.getVIPUserById(this.MmsData.data.id)
      
    },
    getVIPUserById(id){
      axios.post('/VIP/getVIPUserById',{id:id}).then((res) => {
        if(res.code===5000){
          this.form=res.data
          if(this.form.shengri) this.form.shengri=this.form.shengri.substr(0,10)
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getVIPLVList(){
      axios.post('/viplv/list',{fenpeimendianId:this.$store.state.User.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.VIPLVList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getEmpList(){
      axios.post('/emp/list',{fenpeimendianId:this.$store.state.User.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.empList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    setEmpName(){
      let emp=this.empList.find(e=>e.id==this.form.zhuanshukefuId)
      if(emp) this.form.zhuanshukefuName=emp.xingming
    },
    setStoreName(){
      let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
      if(store) this.form.fenpeimendianName=store.storeName
    },
    setVIPLVName(){
      let VIP=this.VIPLVList.find(e=>e.id==this.form.VIPLVId)
      if(VIP) this.form.VIPLVName=VIP.VIPLVName
      else this.form.VIPLVName='无等级'
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      const{id,vipcode,phone,xingming,fenpeimendianId,fenpeimendianName,VIPLVId,VIPLVName,huiyuanfanwei,jifen,zhuanshukefuId,zhuanshukefuName,xingbie,chepaihao,shengri,info}=this.form
      if(testStr(xingming)){
        this.$Mesg({
          content: '会员姓名不能为空',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(phone)){
        this.$Mesg({
          content: '会员手机号码不能为空',
          errOrSuc: 'war',
        })
        return
      }
      axios.post('/VIP/edit',{
        id,vipcode,phone,xingming,fenpeimendianId,fenpeimendianName,VIPLVId,VIPLVName,huiyuanfanwei,jifen,zhuanshukefuId,zhuanshukefuName,xingbie,chepaihao,shengri,info
      }).then((res) => {
        if(res.code===5000){
          this.$Mesg({content:'修改会员成功！'})
          this.$parent.getData(1)
          this.closedWindow()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
