<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>开卡门店：</p>
          <select @change="getData(1)" v-model="form.fenpeimendianId">
            <option :value="0">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>会员等级：</p>
          <select v-model="form.VIPLVId" @change="getData(1)">
            <option value="">全部</option>
            <option :value="item.id" v-for="item in VIPLVList" :key="item.id">{{item.VIPLVName}}</option>
          </select>
        </li>
        <li>
          <p>会员手机号：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.phone">
        </li>
        <li>
          <p>会员姓名：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.xingming">
        </li>
        <li>
          <p>会员卡号：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.vipcode">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    
    <div class="home_list">
      <div class="drags_out">
      <table class="drags_3">
        <thead>
          <tr>
            <th style="text-align:center">编号</th>
            <th>会员卡号</th>
            <th>姓名</th>
            <th>手机号</th>
            <th style="text-align:center">性别</th>
            <th>生日</th>
            <th style="text-align:center">余额</th>
            <th style="text-align:center">积分</th>
            <th>所属门店</th>
            <th>会员级别</th>
            <th style="text-align:center">会员范围</th>
            <th style="text-align:center">通知方式</th>
            <th>专属客服</th>
            <th style="text-align:center">上次消费</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td style="text-align:center">{{item.id}}</td>
            <td>{{item.vipcode}}</td>
            <td>{{item.xingming}}</td>
            <td>{{item.phone}}</td>
            <td style="text-align:center">{{item.xingbie}}</td>
            <td>{{getShengri(item.shengri)}}</td>
            <td style="text-align:center"><strong class="impor">{{parseFloat((item.yue+item.zengsongyue).toFixed(2))}}</strong><br>({{parseFloat(item.yue.toFixed(2))+'+'+parseFloat(item.zengsongyue.toFixed(2))}})</td>
            <td style="text-align:center">{{item.jifen}}</td>
            <td>{{item.fenpeimendianName}}</td>
            <td>{{item.VIPLVName}}</td>
            <td style="text-align:center">{{item.huiyuanfanwei==1?'连锁':'单店'}}</td>
            <td style="text-align:center">{{item.tongzhileixing==1?'短信':item.tongzhileixing==2?"公众号":'短信+公众号'}}</td>
            <td>{{item.zhuanshukefuName}}</td>
            <td style="text-align:center">
              <p :class="powShow(3500)?'blue':''" @click="goUrl(item.scxfjlId)">{{item.shangyicikefuName}}</p>
              <p :class="powShow(3500)?'blue':''" @click="goUrl(item.scxfjlId)">{{getShengri(item.scxfTime)}}</p>
            </td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" @click="setInfoData(item)" v-pow:3601>管理</button>
                <button type="button" class="new" @click="goXF(item.id)" v-pow:3100>消费</button>
                <button type="button" class="ora" @click="goCZ(item.id)" v-pow:3300>充值</button>
                <button type="button" class="cal" @click="openRecordWindow(item)">账单</button>
                <button type="button" class="err" @click="delOneData(item.id,item.xingming)" v-pow:3602>退卡</button>
              </div>
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newProductAdd" v-pow:3200>新增会员</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <div class="list_a">
      <div class="list_a_a">
        <div class="list_a_2" v-if="$store.state.User.jibieId==0">
          <select @change="getVIPCount" v-model="CountForm.fenpeimendianId">
            <option value="">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </div>
        <div class="list_a_1"><p>会员总数：</p>{{VIPCount.zong}}人</div>
        <div class="list_a_1"><p>余额总计：</p>{{parseFloat(VIPCount.yue.toFixed(2))}}元</div>
        <div class="list_a_1"><p>赠送余额总计：</p>{{parseFloat(VIPCount.zs.toFixed(2))}}元</div>
        <div class="list_a_1"><p>会员次卡总计：</p>{{VIPCount.ck}}次</div>
        <div class="list_a_1"><p>积分总计：</p>{{VIPCount.jf}}</div>
        <div class="list_a_1" v-for="item in VIPCount.vipLvList" :key="item.VIPLVId"><p>{{getVIPLVName(item.VIPLVId)}}：</p>{{item.num}}人</div>
        <div class="clear"></div>
      </div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
    <BillMeg v-if="BillMegShow" @closdMeg="closdMeg" :User="User"></BillMeg>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import BillMeg from './BillMeg.vue'
import axios from '@/http/axios'
import moment from 'moment'
import { mapMutations } from "vuex"
export default {
  name: 'packageSettings',
  data() {
    return {
      selectUser:{},
      infoShow: false,
      BillMegShow:false,
      title:'',
      User:{},
      VIPLVList:[],
      dataList:[],
      storeList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        xingming:'',
        VIPLVId:'',
        phone:'',
        vipcode:"",
      },
      CountForm:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
      },
      VIPCount:{
        vipLvList:[],
        yue:0,
        zs:0,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  computed:{
    powShow(){
      return (pow)=>{
        if(this.$store.state.User){
          if(this.$store.state.User.jibieId==0||(this.$store.state.User.pow&&this.$store.state.User.pow.indexOf(pow)>=0)){
            return true
          }else return false
        }else return false
      }
    },
    getVIPLVName(){
      return (id)=>{
        if(id==0) return '无等级'
        else{
          let lvData=this.VIPLVList.find(e=>e.id==id)
          if(lvData) return lvData.VIPLVName
          else return '会员等级已删除'
        }
      }
    },
  },
  created() {
    let phone=this.$route.query.phone
    if(phone) this.form.phone=phone
    this.getData(1)
    this.getVIPLVList()
    if(this.$store.state.User.jibieId==0) this.getStore()
    this.getVIPCount()
  },
  components: {PSinfo,BillMeg},
  mounted() {},
  methods: {
    ...mapMutations(['addNav']),
    goUrl(id){
      if(this.powShow(3500)){
        this.addNav({name:'消费记录',path:'/consumptionRecords'})
        this.$router.push({path:"/consumptionRecords",query:{id}})
      }
    },
    goXF(id){
      this.addNav({name:'顾客结账',path:'/customerCheckout'})
      this.$router.push({path:"/customerCheckout",query:{id}})
    },
    goCZ(id){
      this.addNav({name:'会员充值',path:'/rechargeMember'})
      this.$router.push({path:"/rechargeMember",query:{id}})
    },
    openRecordWindow(user){
     this.User=user
     this.BillMegShow=true
    },
    closdMeg(){
      this.BillMegShow=false
    },
    getShengri(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD")
      else return ''
    },
    getDateTime(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '重要提示',
        content: `确认删除会员：${name} ?`,
        torf: false,
        suc:()=>{
          axios.post('/VIP/del',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    setInfoData(item){
      this.title=item.xingming
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    getVIPLVList(){
      axios.post('/viplv/list',{fenpeimendianId:this.form.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.VIPLVList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/VIPInfo/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getVIPCount(){
      axios.post('/dataCount/VIPCount',this.CountForm).then((res) => {
        if(res.code===5000){
          let VIPCount={
            zong:0,
            yue:0,
            zs:0,
            ck:0,
            jf:0,
            vipLvList:res.data,
          }
          VIPCount.ck=res.ck.num||0
          res.data.forEach(e => {
            VIPCount.zong+=e.num
            VIPCount.yue+=e.yue
            VIPCount.zs+=e.zs
            VIPCount.jf+=e.jifen
          });
          this.VIPCount=VIPCount
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    closedWindow(){
      this.infoShow=false
    },
    newProductAdd(){
      this.$router.push('/ActivateVIP')
    },
  },
}
</script>
